import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { searchAndInitialize, preventDefault } from "../Utils"
import * as Inputs from "../Inputs"
import DomElement from "../DomElement"
import { getRootElement } from "../DomFunctions"

const CLASS_BACKDROP = "backdrop"
const CLASS_BACKDROP_OPEN = "backdrop--open"

const CLASS_OPEN = "modal--open"
const CLASS_TRIGGER = "modal-trigger"

const CLASS_BODY = "modal__body"

const CLASS_BUTTONS_OKAY = ".modal-close"
const CLASS_BUTTONS_CLOSE = ".modal-cancel"

/**
 * A component to open and close modal dialogs. It also handles cancellation and makes
 * sure that the modal background is present in the DOM.
 */
class Modal extends DomElement<HTMLElement> {
  private _okayHandler: (e: Event) => void
  private _cancelHandler: (e: Event) => void
  private _keydownHandler: (e: Event) => void
  private _windowClickHandler?: (e: Event) => void
  private _triggerClickHandler?: (e: Event) => void

  private _backdrop!: DomElement<HTMLDivElement>
  private _backdropParent!: Element

  constructor(element: HTMLElement) {
    super(element)

    this._okayHandler = this.close.bind(this)
    this._cancelHandler = this._handleClick.bind(this)
    this._keydownHandler = this._handleKeydown.bind(this)

    this._initialize()
  }

  /**
   * Initializes the range modal component.
   * @private
   */
  protected _initialize() {

    // Create the backdrop
    this._backdrop = new DomElement<HTMLDivElement>("div")
      .addClass(CLASS_BACKDROP)

    this._backdropParent = getRootElement()
    this._subscribeToTrigger()
  }

  protected _subscribeToTrigger() {
    const triggerId = this.element.id
    if (!triggerId) {
      return
    }

    this._triggerClickHandler = this.open.bind(this)

    let triggerElements = document.querySelectorAll(`.${CLASS_TRIGGER}[href=${triggerId}]`)
    for (let triggerElement of triggerElements) {
      triggerElement.addEventListener("click", this._triggerClickHandler!)
    }
  }

  protected _unsubscribeFromTrigger() {
    const triggerId = this.element.id
    if (!triggerId) {
      return
    }

    let triggerElements = document.querySelectorAll(`.${CLASS_TRIGGER}[href=${triggerId}]`)
    for (let triggerElement of triggerElements) {
      triggerElement.removeEventListener("click", this._windowClickHandler!)
    }

    this._triggerClickHandler = undefined
  }

  protected _handleKeydown(event: KeyboardEvent) {
    let keycode = event.which || event.keyCode

    if (keycode === Inputs.KEY_ESCAPE) {
      // handle Escape key (ESC)
      this.cancel()
      return
    }
  }

  protected _handleClick(event: MouseEvent) {
    preventDefault(event)
    this.cancel()
  }

  protected _close() {
    enableBodyScroll(this.element)

    document.removeEventListener("keydown", this._keydownHandler)
    this._backdrop.element.removeEventListener("click", this._cancelHandler)

    this._backdrop.removeClass(CLASS_BACKDROP_OPEN)
    this.removeClass(CLASS_OPEN)

    for (let closeButton of this.element.querySelectorAll(CLASS_BUTTONS_CLOSE)) {
      closeButton.removeEventListener("click", this._cancelHandler)
    }

    for (let okayButton of this.element.querySelectorAll(CLASS_BUTTONS_OKAY)) {
      okayButton.removeEventListener("click", this._okayHandler)
    }

    setTimeout(() => {
      // remove the backdrop from the body
      this._backdropParent.removeChild(this._backdrop.element)
    }, 300)
  }

  /**
   * Opens the modal dialog.
   * @fires Modal#opened
   */
  public open() {
    disableBodyScroll(this.element, {
      allowTouchMove: (el) => {
        let currentEl = el

        while (currentEl && currentEl !== document.body) {
          // Check if the user is scrolling the modal body
          if (currentEl.classList.contains(CLASS_BODY)) {
            // Check if the element overflows
            if (currentEl.scrollHeight > currentEl.clientHeight) {
              return true
            }
          }

          currentEl = currentEl.parentNode as HTMLElement
        }

        return false
      }
    })

    // add the backdrop to the body
    this._backdropParent.appendChild(this._backdrop.element)

    // set the element to flex as it is initially hidden
    this.element.style.display = "flex"

    // remove the style after the animation completes
    setTimeout(() => {
      this.element.style.display = ""
    }, 800)

    // wait a bit to allow the browser to catch up and show the animation
    setTimeout(() => {
      this.addClass(CLASS_OPEN)
      this._backdrop.addClass(CLASS_BACKDROP_OPEN)

      document.addEventListener("keydown", this._keydownHandler)

      this._backdrop.element.addEventListener("click", this._cancelHandler)

      for (let closeButton of this.element.querySelectorAll(CLASS_BUTTONS_CLOSE)) {
        closeButton.addEventListener("click", this._cancelHandler)
      }

      for (let okayButton of this.element.querySelectorAll(CLASS_BUTTONS_OKAY)) {
        okayButton.addEventListener("click", this._okayHandler)
      }

      this.dispatchEvent("opened")
    }, 50)
  }

  /**
   * Cancels (and closes) the modal dialog.
   * @fires Modal#cancelled
   * @fires Modal#closed
   */
  public cancel() {
    this.dispatchEvent("cancelled")
    this._close()
  }

  /**
   * Closes the modal dialog.
   * @fires Modal#closed
   */
  public close() {
    this._close()
    this.dispatchEvent("closed")
  }

  /**
   * Destroys the component and frees all references.
   */
  public destroy() {
    this.cancel()
    this._unsubscribeFromTrigger()
  }

  /**
   * Fired when the modal dialog is opened by the anchor link or using the
   * {@link Modal#open} method.
   * @event Modal#opened
   * @type {object}
   */

  /**
   * Fired when the modal dialog is closed by the user or using the
   * {@link Modal#close} method.
   * @event Modal#closed
   * @type {object}
   */

  /**
   * Fired when the modal dialog is cancelled by the user or using the
   * {@link Modal#cancel} method.
   * @event Modal#cancelled
   * @type {object}
   */
}

export function init() {
  searchAndInitialize<HTMLElement>(".modal", (e) => {
    new Modal(e)
  })
}

export default Modal
