import { searchAndInitialize } from "../Utils"
import DomElement from "../DomElement"
import { parentWithClass, getRootElement } from "../DomFunctions"

const CLASS_BORDER = "empty-state__border"
const CLASS_BORDER_MODAL = "empty-state__border--modal"
const CLASS_ACTIVE = "is-active"
const CLASS_HASFILES = "has-files"
const CLASS_MODAL = "empty-state--modal"
const CLASS_MODAL_CONTENT = "modal__content"

const QUERY_MODAL_BODY = ".modal__body"
const QUERY_FILE = "input[type='file']"

/**
 * Empty state pattern
 */
class EmptyState extends DomElement {
  private _border!: DomElement<HTMLDivElement>

  private _fileInput: HTMLInputElement
  private _button: HTMLLabelElement

  private _dragArea!: Element

  private _fileChangedHandler: (event: Event) => void
  private _preventEventsHandler: (event: Event) => void
  private _dragEnterHandler: (event: Event) => void
  private _dragLeaveHandler: (event: Event) => void
  private _dropHandler: (event: Event) => void

  private _isDragging: boolean

  /**
   * Creates and initializes the Empty-State pattern component.
   * @param {DomElement} - root element of the empty-state pattern.
   */
  constructor(element: Element) {
    super(element)

    this._fileInput = this.element.querySelector(QUERY_FILE)! as HTMLInputElement
    this._button = this.element.querySelector("label")!

    this._fileChangedHandler = this._handleFileChanged.bind(this)
    this._preventEventsHandler = this._preventDragEvents.bind(this)
    this._dragEnterHandler = this._handleDragEnter.bind(this)
    this._dragLeaveHandler = this._handleDragLeave.bind(this)
    this._dropHandler = this._handleDrop.bind(this)

    this._isDragging = false

    this._initialize()
  }

  protected _initialize() {
    if (this.hasClass(CLASS_MODAL)) {
      // handle modal dialogs
      this._dragArea = parentWithClass(this.element, CLASS_MODAL_CONTENT)!
      let borderArea = this._dragArea.querySelector(QUERY_MODAL_BODY)!
      borderArea.setAttribute("style", "pointer-events: none;")

      this._border = new DomElement<HTMLDivElement>("div")
        .addClass(CLASS_BORDER)
        .addClass(CLASS_BORDER_MODAL)

      borderArea.appendChild(this._border.element)

    } else {
      // normal modal dialog
      this._dragArea = this.element
      let borderArea = getRootElement()

      this._border = new DomElement<HTMLDivElement>("div")
        .addClass(CLASS_BORDER)

      if (!borderArea.querySelector(`.${CLASS_BORDER}`)) {
        borderArea.appendChild(this._border.element)
      }
    }

    const form = this.element.querySelector("form")!

    for (let event of [ "drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop" ]) {
      this.element.addEventListener(event, this._preventEventsHandler)
      form.addEventListener(event, this._preventEventsHandler)
      this._dragArea.addEventListener(event, this._preventEventsHandler)
    }

    this._dragArea.addEventListener("dragover", this._dragEnterHandler)
    this._dragArea.addEventListener("dragenter", this._dragEnterHandler)

    this._dragArea.addEventListener("dragleave", this._dragLeaveHandler)
    this._dragArea.addEventListener("dragend", this._dragLeaveHandler)
    this._dragArea.addEventListener("drop", this._dragLeaveHandler)

    this._dragArea.addEventListener("drop", this._dropHandler)
    this._fileInput.addEventListener("change", this._fileChangedHandler)
  }

  protected _preventDragEvents(e: Event) {
    e.preventDefault()
    e.stopPropagation()

    return false
  }

  protected _handleDragEnter() {
    if (this._isDragging === true) {
      return
    }

    this._isDragging = true
    this._button.setAttribute("style", "pointer-events: none;")

    this.addClass(CLASS_ACTIVE)
    this._border.addClass(CLASS_ACTIVE)
  }

  protected _handleDragLeave() {
    if (this._isDragging === false) {
      return
    }

    this._isDragging = false
    this._button.setAttribute("style", "")

    this.removeClass(CLASS_ACTIVE)
    this._border.removeClass(CLASS_ACTIVE)
  }

  protected _handleDrop(e: DragEvent) {
    (this._fileInput as any).files = e.dataTransfer.files
  }

  protected _handleFileChanged() {
    let files = this._fileInput.files

    if (files && files.length > 0) {
      this.addClass(CLASS_HASFILES)
    } else {
      this.removeClass(CLASS_HASFILES)
    }
  }

  /**
   * Gets the currently selected files.
   */
  get files() {
    return this._fileInput.files
  }
}

export function init() {
  searchAndInitialize(".empty-state", (e) => {
    new EmptyState(e)
  })
}

export default EmptyState
