import { searchAndInitialize, clamp, preventDefault, remove } from "../Utils"
import DomElement from "../DomElement"
import * as Inputs from "../Inputs"
import * as Dom from "../DomFunctions"

const QUERY_SLIDER = ".carousel__container"
const QUERY_SLIDE_AREA = ".carousel__slider"
const QUERY_WRAPPER = ".carousel__slider-wrapper"

const QUERY_PAGINATION = ".carousel__pagination"

const CLASS_ACTIVE = "slide--active"
const CLASS_PREV = "slide--prev"
const CLASS_NEXT = "slide--next"

const CLASS_BULLET = "pagination-bullet"
const CLASS_BULLET_ACTIVE = "pagination-bullet--active"

const QUERY_BTN_PREV = ".carousel__button-prev"
const QUERY_BTN_NEXT = ".carousel__button-next"
const QUERY_BTN_WRAPPER = ".carousel__button-wrapper"

const ATTRIBUTE_INDEX = "js-index"

const ANIMATION_DURATION = 350
const ANIMATION_EASING = "ease-in-out"

const TOUCH_DURATION = 300
const TOUCH_DELTA_MIN = 25

export interface SlideProperties {
  right: number
  left: number
  visible: boolean
  index: number
  width: number
  marginLeft: number
  marginRight: number
}

export type Direction = 0 | -1 | 1

/**
 * The carousel component definition.
 */
class Carousel extends DomElement<HTMLElement> {
  private _slider: HTMLElement
  private _wrapper: HTMLElement
  private _pagination?: HTMLElement
  private _slideArea: HTMLElement

  private _btnWrapper: HTMLElement
  private _prevCtrl: HTMLElement
  private _nextCtrl: HTMLElement

  private _slides: HTMLElement[]

  private _index: number
  private _slidesPerGroup: number

  private _sliderWrapper: SliderWrapper

  private _additionalSlideMargin: number

  private _resizeHandler: (event: Event) => void
  private _prevHandler: (event: Event) => void
  private _nextHandler: (event: Event) => void
  private _paginationClickHandler: (event: Event) => void
  private _keydownHandler: (event: Event) => void

  private _handleTouchstart: (event: Event) => void
  private _handleTouchmove: (event: Event) => void
  private _handleTouchend: (event: Event) => void

  private _breakpointPhone!: HTMLDivElement
  private _breakpointTablet!: HTMLDivElement
  private _breakpointDesktop!: HTMLDivElement

  private _touchOffset?: {
    x: number;
    time: number;
  }

  private _delta?: {
    x: number;
    lastMove: number;
  }

  private _frameWidth?: number

  /**
   * Creates and initializes the carousel component.
   * @param {DomElement} element - The root element of the Carousel component.
   * @param {Number} index - The initial index.
   */
  constructor(element: HTMLElement, index = 0) {
    super(element)

    this._slider = this.element.querySelector(QUERY_SLIDER)! as HTMLElement
    this._wrapper = this._slider.querySelector(QUERY_WRAPPER)! as HTMLElement
    this._pagination = this._slider.querySelector(QUERY_PAGINATION) as HTMLElement
    this._slideArea = this._slider.querySelector(QUERY_SLIDE_AREA)! as HTMLElement

    this._btnWrapper = this.element.querySelector(QUERY_BTN_WRAPPER)! as HTMLElement
    this._prevCtrl = this.element.querySelector(QUERY_BTN_PREV)! as HTMLElement
    this._nextCtrl = this.element.querySelector(QUERY_BTN_NEXT)! as HTMLElement

    this._slides = []

    this._index = index || 0
    this._slidesPerGroup = 1

    this._sliderWrapper = new SliderWrapper(this._wrapper, this._slideArea, this.element)
    this._sliderWrapper.index = this._index
    this._additionalSlideMargin = 0

    this._resizeHandler = this._onresize.bind(this)
    this._prevHandler = this.prev.bind(this)
    this._nextHandler = this.next.bind(this)
    this._paginationClickHandler = this._handlePaginationClick.bind(this)
    this._keydownHandler = this._handleKeydown.bind(this)

    this._handleTouchstart = this._onTouchstart.bind(this)
    this._handleTouchmove = this._onTouchmove.bind(this)
    this._handleTouchend = this._onTouchend.bind(this)

    this._initialize()
    this.slide(this._index, 0, false)

    this._updateCtrlOffsets()
  }

  /**
   * Initializes the carousel component.
   * @private
   */
  protected _initialize() {
    // responsive helpers
    this._breakpointPhone = new DomElement<HTMLDivElement>("div")
      .addClass("js-phone")
      .element

    this._breakpointTablet = new DomElement<HTMLDivElement>("div")
      .addClass("js-tablet")
      .element

    this._breakpointDesktop = new DomElement<HTMLDivElement>("div")
      .addClass("js-desktop")
      .element

    this.element.appendChild(this._breakpointPhone)
    this.element.appendChild(this._breakpointTablet)
    this.element.appendChild(this._breakpointDesktop)

    if (this._prevCtrl && this._nextCtrl) {
      this._prevCtrl.addEventListener("click", this._prevHandler)
      this._nextCtrl.addEventListener("click", this._nextHandler)
    }

    if (this._pagination) {
      this._pagination.addEventListener("click", this._paginationClickHandler)
    }

    this._slides = Array.from(this._wrapper.children) as HTMLElement[]
    if (this._slides.length === 0) {
      throw Error("Provide at least one slide to the slider")
    }

    for (let i = 0; i < this._slides.length; i++) {
      let slide = this._slides[i]
      slide.setAttribute(ATTRIBUTE_INDEX, String(i))
    }

    this._updateResponsiveOptions()
    this._sliderWrapper.initialize()

    this.reset()

    this.element.addEventListener("keydown", this._keydownHandler)

    this._slideArea.addEventListener("mousedown", this._handleTouchstart)
    this._slideArea.addEventListener("touchstart", this._handleTouchstart)

    window.addEventListener("resize", this._resizeHandler)
    window.addEventListener("orientationchange", this._resizeHandler)
  }

  protected _isBreakpointActive(breakpoint: HTMLDivElement) {
    let style = window.getComputedStyle(breakpoint)
    return style.visibility === "visible"
  }

  protected _onresize() {
    this.reset()
    this._updateCtrlOffsets()
  }

  /**
   * Makes sure the index is always in the range of available slide
   * In case it's to high or to low it is wrapped around
   * @param {Number} index - The index to adjust and sanitize
   * @returns {Number} index - The adjusted index
   * @private
   */
  protected _adjustIndex(index: number) {
    if (typeof index !== "number") {
      index = 0
    }

    if (index < 0) {
      index = this._wrapround(index, 0, this._slides.length)
    } else if (index >= this._slides.length) {
      index %= this._slides.length
    }

    return Math.floor(index / this._slidesPerGroup) * this._slidesPerGroup
  }

  protected _wrapround(n: number, min: number, max: number) {
    if (n >= max) {
      return min
    }

    if (n < min) {
      return max - 1
    }

    return n
  }

  protected _wraproundCount(a: number, b: number, min: number, max: number, direction: Direction) {
    if (direction === 0) {
      return 0
    }

    if (a < min || a >= max) {
      throw new Error(`Argument 'a' is out of range, Value: ${a} Min: ${min}, Max: ${max}`)
    }

    if (b < min || b >= max) {
      throw new Error(`Argument 'b' is out of range, Value: ${b} Min: ${min}, Max: ${max}`)
    }

    let i = 0
    while (a !== b) {
      i++
      a = this._wrapround(a + direction, min, max)
    }

    return i
  }

  protected _updateCtrlOffsets() {
    if (!this._nextCtrl || !this._prevCtrl || !this._btnWrapper) {
      return
    }

    let prevCtrlMargin = 0
    let nextCtrlMargin = 0

    if (this._slidesPerGroup > 1) {
      let wrapperRect = this._btnWrapper.getBoundingClientRect()

      const prevSlideCount = Math.floor(0.5 * this._slidesPerGroup)
      const rightIndex = this._sliderWrapper.index + prevSlideCount + 1

      let leftIndex = this._sliderWrapper.index - 1
      if (this._slidesPerGroup % 2 !== 0) {
        leftIndex -= prevSlideCount
      }

      if ((leftIndex >= 0 && leftIndex < this._wrapper.children.length) &&
        (rightIndex >= 0 && rightIndex < this._wrapper.children.length)) {
        let leftSlide = this._sliderWrapper.getSlideProperties(leftIndex)
        let rightSlide = this._sliderWrapper.getSlideProperties(rightIndex)

        let btnWidth = this._prevCtrl.offsetWidth
        if (btnWidth <= 0) {
          btnWidth = 60
        }

        prevCtrlMargin = leftSlide.right - wrapperRect.left - btnWidth
        nextCtrlMargin = wrapperRect.right - rightSlide.left - btnWidth
      }
    }

    let left = prevCtrlMargin !== 0 ? `${prevCtrlMargin}px` : ""
    this._prevCtrl.style.left = left

    let right = nextCtrlMargin !== 0 ? `${nextCtrlMargin}px` : ""
    this._nextCtrl.style.right = right
  }

  protected _updateActiveSlides(nextIndex: number) {
    const prevSlideCount = Math.floor(0.5 * (this._slidesPerGroup - 1))
    const evenGroup = this._slidesPerGroup % 2 === 0

    for (let i = 0; i < this._wrapper.children.length; i++) {
      let slide = this._wrapper.children[i]

      if (i === nextIndex || (evenGroup && i === nextIndex + 1)) {
        Dom.addClass(slide, CLASS_ACTIVE)
      } else {
        Dom.removeClass(slide, CLASS_ACTIVE)
      }

      if (i < nextIndex && i >= nextIndex - prevSlideCount) {
        Dom.addClass(slide, CLASS_PREV)
      } else {
        Dom.removeClass(slide, CLASS_PREV)
      }

      if (i > nextIndex && (i <= nextIndex + prevSlideCount || (evenGroup && i <= nextIndex + 1 + prevSlideCount))) {
        Dom.addClass(slide, CLASS_NEXT)
      } else {
        Dom.removeClass(slide, CLASS_NEXT)
      }
    }
  }

  /**
   * Updates and creates the pagination bullets.
   * @private
   */
  protected _updatePagination() {
    if (!this._pagination) {
      return
    }

    let to = this._index

    let bullets = this._pagination.children
    let totalItems = Math.max(this._slides.length, bullets.length)
    let slideCount = Math.ceil(this._slides.length / this._slidesPerGroup)
    let activeSlideIndex = Math.floor(to / this._slidesPerGroup)

    for (let i = 0; i < totalItems; i++) {
      let bullet

      if (bullets.length > i) {
        if (bullets.length <= slideCount) {
          bullet = bullets[i]
        } else {
          remove(bullets[i])
        }
      } else if (i < slideCount) {
        bullet = new DomElement("div")
          .addClass(CLASS_BULLET)
          .element
        this._pagination.appendChild(bullet)
      }

      if (bullet && i < slideCount) {
        if (i === activeSlideIndex) {
          Dom.addClass(bullet, CLASS_BULLET_ACTIVE)
        } else {
          Dom.removeClass(bullet, CLASS_BULLET_ACTIVE)
        }
      }
    }
  }

  protected _handlePaginationClick(e: MouseEvent) {
    if (!Dom.hasClass(e.target as Element, CLASS_BULLET)) {
      return
    }

    let index = Array.from(this._pagination!.children).indexOf(e.target as Element)
    let slideNumber = index * this._slidesPerGroup

    this.slideTo(slideNumber)
  }

  protected _handleKeydown(event: KeyboardEvent) {
    let keycode = event.which || event.keyCode

    switch (keycode) {
      case Inputs.KEY_ARROW_LEFT:
        this.prev()
        break
      case Inputs.KEY_ARROW_RIGHT:
        this.next()
        break
      case Inputs.KEY_ESCAPE:
        this.element.blur()
        break
      default:
    }
  }

  protected _onTouchstart(event: TouchEvent | MouseEvent) {
    const touch = (event as TouchEvent).touches ? (event as TouchEvent).touches[0] : event as MouseEvent

    this._slideArea.removeEventListener("mousedown", this._handleTouchstart)
    this._slideArea.removeEventListener("touchstart", this._handleTouchstart)

    this._sliderWrapper.beginDrag()
    const { pageX } = touch

    this._touchOffset = {
      x: pageX,
      time: Date.now()
    }

    this._delta = {
      x: 0,
      lastMove: pageX
    }

    document.addEventListener("mousemove", this._handleTouchmove)
    document.addEventListener("touchmove", this._handleTouchmove)

    document.addEventListener("mouseup", this._handleTouchend)
    document.addEventListener("mouseleave", this._handleTouchend)
    document.addEventListener("touchend", this._handleTouchend)
  }

  protected _onTouchmove(event: TouchEvent | MouseEvent) {
    const touch = (event as TouchEvent).touches ? (event as TouchEvent).touches[0] : event as MouseEvent
    const { pageX } = touch

    let deltaMove = pageX - this._delta!.lastMove

    this._delta = {
      x: pageX - this._touchOffset!.x,
      lastMove: pageX
    }

    if (this._touchOffset) {
      preventDefault(event)

      this._sliderWrapper.move(deltaMove)
      this._cloneSlidesToFitWrapper(false, deltaMove)
    }
  }

  protected _onTouchend() {
    const duration = this._touchOffset ? Date.now() - this._touchOffset.time : undefined

    const isValid = Number(duration) < TOUCH_DURATION &&
      Math.abs(this._delta!.x) > TOUCH_DELTA_MIN ||
      Math.abs(this._delta!.x) > this._frameWidth! / 3

    if (isValid) {
      const direction = clamp(this._delta!.x, -1, 1) * -1 as Direction
      this.slide(false, direction, true)

      this._sliderWrapper.endDrag()
    } else {
      // Slide back to the starting point of the drag operation
      this._sliderWrapper.cancelDrag()
    }

    this._touchOffset = undefined

    this._slideArea.addEventListener("mousedown", this._handleTouchstart)
    this._slideArea.addEventListener("touchstart", this._handleTouchstart)

    document.removeEventListener("mousemove", this._handleTouchmove)
    document.removeEventListener("mouseup", this._handleTouchend)
    document.removeEventListener("mouseleave", this._handleTouchend)
    document.removeEventListener("touchmove", this._handleTouchmove)
    document.removeEventListener("touchend", this._handleTouchend)
  }

  /**
   * Updated parameters in regard to the currently active responsive
   * breakpoint.
   * @private
   */
  protected _updateResponsiveOptions() {
    if (this._isBreakpointActive(this._breakpointPhone)) {
      this._slidesPerGroup = 1
    }

    if (this._isBreakpointActive(this._breakpointTablet)) {
      this._slidesPerGroup = 2
    }

    if (this._isBreakpointActive(this._breakpointDesktop)) {
      this._slidesPerGroup = 3
    }

    this._sliderWrapper.slidesPerGroup = this._slidesPerGroup
  }

  /**
   * Clones the requested slide and adds it to the slider.
   * @param {Number} index - The original slide index of the template slide
   * @param {Number} direction - The direction in which to add the slides, -1 for left, 1 for right
   * @private
   */
  protected _cloneSlide(index: number, direction: number) {
    let clone = this._slides[index].cloneNode(true) as HTMLElement
    Dom.removeClass(clone, CLASS_ACTIVE)
    Dom.removeClass(clone, CLASS_PREV)
    Dom.removeClass(clone, CLASS_NEXT)

    this._sliderWrapper.addSlide(clone, direction)

    let slideMargin = this._additionalSlideMargin > 0 ? `${this._additionalSlideMargin}px` : ""
    clone.style.marginLeft = slideMargin
    clone.style.marginRight = slideMargin

    return clone.offsetWidth
  }

  /**
   * Clones and adds the requested ammount of slides.
   * @param {Number} slideCount - The number of slides to add
   * @param {Number} direction - The direction in which to add the slides, -1 for left, 1 for right
   * @private
   */
  protected _cloneSlidesByCount(slideCount: number, direction: Direction) {
    let originalIndex = direction < 0 ? 0 : this._wrapper.children.length - 1
    let index = parseInt(this._wrapper.children[originalIndex].getAttribute(ATTRIBUTE_INDEX)!, 10)

    while (slideCount > 0) {
      index = this._wrapround(index + direction, 0, this._slides.length)
      this._cloneSlide(index, direction)
      slideCount--
    }
  }

  /**
   * Calculates the scroll clount and inserts the required ammount of slides
   * in the apropriate direction.
   * @param {Number} nextIndex - The slide to scroll to
   * @param {Number} direction - The direction of the scroll
   * @private
   */
  protected _cloneSlidesByScrollCount(nextIndex: number, direction: Direction) {
    const scrollCount = this._wraproundCount(this._index, nextIndex, 0, this._slides.length, direction)

    const outerSlideProps = this._sliderWrapper.getSlideProperties(direction > 0 ? this._wrapper.children.length - 1 : 0)
    const indexToOuterSlideCount = this._wraproundCount(this._index, outerSlideProps.index, 0, this._slides.length, direction)

    const slidesToInsert = scrollCount - indexToOuterSlideCount
    if (slidesToInsert > 0) {
      this._cloneSlidesByCount(slidesToInsert, direction)
    }
  }

  protected _cloneSlidesByToFill(spaceToFill: number, direction: Direction) {
    let originalIndex = direction < 0 ? 0 : this._wrapper.children.length - 1
    let index = parseInt(this._wrapper.children[originalIndex].getAttribute(ATTRIBUTE_INDEX)!, 10)

    while (spaceToFill > 0) {
      index = this._wrapround(index + direction, 0, this._slides.length)
      spaceToFill -= this._cloneSlide(index, direction)
    }
  }

  protected _cloneSlidesToFitWrapper(cleanup = true, slideDelta = 0) {
    const realIndex = this._sliderWrapper.index
    let first: SlideProperties
    let last: SlideProperties

    if (cleanup === false) {
      first = this._sliderWrapper.getSlideProperties(0)
      last = this._sliderWrapper.getSlideProperties(this._wrapper.children.length - 1)
    } else {
      let result = this._sliderWrapper.getRemovableSlides(slideDelta)
      first = result.first!
      last = result.last!

      // Remove the slides from view
      for (let i = result.slides.length - 1; i >= 0; i--) {
        if (result.slides[i] === true) {
          this._sliderWrapper.removeSlide(i)
        }
      }
    }

    let spaceToFill = this._sliderWrapper.getEmptySpace(first.left, last.right)

    // Check if additional slides are required on the left
    if (first.visible === true && spaceToFill.left > 0) {
      this._cloneSlidesByToFill(spaceToFill.left, -1)
    }

    // Check if additional slides are required on the right
    if (last.visible === true && spaceToFill.right > 0) {
      this._cloneSlidesByToFill(spaceToFill.right, 1)
    }

    return realIndex - this._sliderWrapper.index
  }

  /**
   * Gets the real (wrapper) index for the slide with the given original index
   * @param {Number} index - The index to search for
   * @param {Number} direction - The direction in which to search
   * @returns {Number} The wrapper index
   * @private
   */
  protected _getRealIndexFor(index: number, direction: Direction) {
    let i = this._sliderWrapper.index
    while (i >= 0 && i < this._wrapper.children.length) {
      let slideIndex = parseInt(this._wrapper.children[i].getAttribute(ATTRIBUTE_INDEX)!, 10)
      if (slideIndex === index) {
        return i
      }

      i += direction
    }

    throw new Error(`Cloud not find real index for slide ${index} in direction ${direction}`)
  }

  /**
   * Gets the index of the current active slide. If the slides are grouped evenly
   * the active slide is always the first in the group.
   * @returns {Number} The index of the active slide.
   */
  get index() {
    return this._index
  }

  public reset() {
    this._frameWidth = this._slider.getBoundingClientRect()
      .width || this._slider.offsetWidth

    this._updateResponsiveOptions()

    if (this._nextCtrl) {
      (this._nextCtrl as any).disabled = false
    }

    if (this._prevCtrl) {
      (this._prevCtrl as any).disabled = false
    }

    if (this._slidesPerGroup === 1) {
      let style = window.getComputedStyle(this._slider.parentElement!)
      let parentWidth = this._slider.parentElement!.clientWidth + (parseFloat(style.marginLeft!) || 0) + (parseFloat(style.marginRight!) || 0)

      let outerMargin = Math.ceil(parentWidth - this._frameWidth)
      this._additionalSlideMargin = Math.ceil(outerMargin * 0.5) + 1
    } else {
      this._additionalSlideMargin = 0
    }

    let slideMargin = this._additionalSlideMargin > 0 ? `${this._additionalSlideMargin}px` : ""
    for (let i = 0; i < this._wrapper.children.length; i++) {
      let slide = this._wrapper.children[i] as HTMLElement
      slide.style.marginLeft = slideMargin
      slide.style.marginRight = slideMargin
    }

    this._sliderWrapper.onresize()
    this._cloneSlidesToFitWrapper(false)
    this._sliderWrapper.moveTo(this._sliderWrapper.index)

    this._updatePagination()
    this._updateActiveSlides(this._sliderWrapper.index)
  }

  /**
   * Moves the slider to the next item.
   */
  public prev() {
    this.slide(false, -1)
  }

  /**
   * Moves the slider to the previous item.
   */
  public next() {
    this.slide(false, 1)
  }

  public slide(nextIndex: number | false, direction?: Direction, animate = true) {
    if (typeof nextIndex !== "number") {
      if (direction! > 0) {
        nextIndex = this._index + this._slidesPerGroup
        direction = 1
      } else {
        nextIndex = this._index - this._slidesPerGroup
        direction = -1
      }
    }

    nextIndex = this._adjustIndex(nextIndex)

    if (!direction) {
      direction = clamp(nextIndex - this._index, -1, 1) as Direction
    }

    // Make sure there are enought slides on screen
    this._cloneSlidesToFitWrapper(false)

    // Make sure there are enough slides for the scroll operation
    this._cloneSlidesByScrollCount(nextIndex, direction)

    let realIndex = this._getRealIndexFor(nextIndex, direction)
    let slideDelta = this._sliderWrapper.getSlideDelta(realIndex)
    realIndex = Math.max(realIndex - this._cloneSlidesToFitWrapper(true, slideDelta), 0)

    this._sliderWrapper.moveTo(realIndex, undefined, animate)

    // Update the active index
    this._index = nextIndex

    // Mark slides as active
    this._updatePagination()
    this._updateActiveSlides(realIndex)

    // console.log(`Performed slide to ${this._index}, realIndex: ${this._sliderWrapper.index}`)
  }

  /**
   * Moves the slider to the selected slide.
   * @param {Number} index - The index of the slide to slide to.
   * @param {Boolean} animate - `True` if the slide should be animated; otherwise `false`. Defaults to `true`.
   */
  public slideTo(index: number, animate = true) {
    this.slide(index, undefined, animate)
  }

  /**
   * Destroys the components and frees all references.
   */
  public destroy() {
    window.removeEventListener("resize", this._resizeHandler)
    window.removeEventListener("orientationchange", this._resizeHandler)

    this.element.removeEventListener("keydown", this._keydownHandler)
    this._slideArea.removeEventListener("mousedown", this._handleTouchstart)
    this._slideArea.removeEventListener("touchstart", this._handleTouchstart)

    this._breakpointPhone.remove()
    this._breakpointTablet.remove()
    this._breakpointDesktop.remove()

    if (this._prevCtrl && this._nextCtrl) {
      this._prevCtrl.removeEventListener("click", this._prevHandler)
      this._nextCtrl.removeEventListener("click", this._nextHandler)
    }

    (this as any)._prevCtrl = undefined;
    (this as any)._nextCtrl = undefined

    if (this._pagination) {
      this._pagination.removeEventListener("click", this._paginationClickHandler);
      (this as any)._pagination = undefined
    }

    this._sliderWrapper.destroy();
    (this as any)._sliderWrapper = undefined
  }
}

const TRANSFORM = "transform"
const DURATION = "transitionDuration"
const TIMING = "transitionTimingFunction"

class SliderWrapper {
  private _wrapperElement: HTMLElement
  private _slideAreaElement: HTMLElement
  private _carouselElement: HTMLElement

  private _position: number
  private _index: number

  private _isdragging: boolean
  private _dragStartPosition?: number

  private _areaOffset?: number

  private _slidesPerGroup!: number

  private _containerMin!: number
  private _containerMax!: number

  constructor(wrapperElement: HTMLElement, slideAreaElement: HTMLElement, carouselElement: HTMLElement) {
    this._wrapperElement = wrapperElement
    this._slideAreaElement = slideAreaElement
    this._carouselElement = carouselElement

    this._position = 0
    this._index = 0
    this._isdragging = false
  }

  protected _getSlide(index: number) {
    if (index < 0 || index >= this._wrapperElement.children.length) {
      throw new Error(`Argument 'index' is out of range, Value: ${index} Min: 0, Max: ${this._wrapperElement.children.length - 1}`)
    }

    return this._wrapperElement.children[index] as HTMLElement
  }

  protected _setTransform(targetPosition: number, animated = false, duration = ANIMATION_DURATION, ease = ANIMATION_EASING) {
    if (animated === false) {
      duration = 0
    }

    const style = this._wrapperElement.style
    if (style) {
      style[DURATION] = `${duration}ms`
      style[TIMING] = ease

      // No sub pixel transitions.
      targetPosition = Math.floor(targetPosition)

      style[TRANSFORM] = `translate(${targetPosition}px, 0)`
      this._position = targetPosition
    }
  }

  protected _getWrapperSlidePosition(index: number) {
    const wrapperCenter = (0.5 * this._wrapperElement.offsetWidth)
    const slide = this._getSlide(index)

    let result = 0
    // Calculate the position of the slide (centered)
    if (this._slidesPerGroup % 2 === 0) {
      let slideStyle = window.getComputedStyle(slide)
      let slideMargin = slideStyle ? parseInt(slideStyle.marginRight!, 10) : 0
      // Centered to the space between the two center slides of the group
      result = -slide.offsetLeft - (slide.clientWidth) + wrapperCenter - slideMargin
    } else {
      result = -slide.offsetLeft - (0.5 * slide.clientWidth) + wrapperCenter
    }

    return result
  }

  get position() {
    return this._position
  }

  get index() {
    return this._index
  }

  set index(index: number) {
    this._index = index
  }

  set slidesPerGroup(value: number) {
    this._slidesPerGroup = value
  }

  public initialize() {
    this.onresize()
  }

  public onresize() {
    // update the area offset for slide position calculation
    this._areaOffset = this._slideAreaElement.getBoundingClientRect().left

    // Get the container dimensions
    const containerRect = this._carouselElement.getBoundingClientRect()
    this._containerMin = containerRect.left
    this._containerMax = containerRect.right
  }

  public beginDrag() {
    this._isdragging = true
    this._dragStartPosition = this._position
  }

  public cancelDrag() {
    this._isdragging = false
    this._setTransform(this._dragStartPosition!, true, ANIMATION_DURATION, ANIMATION_EASING)

    this._dragStartPosition = undefined
  }

  public endDrag() {
    this._isdragging = false
    this._dragStartPosition = undefined
  }

  public move(delta: number, animated = false, duration = ANIMATION_DURATION, ease = ANIMATION_EASING) {
    delta = Math.trunc(delta)
    if (Math.abs(delta) <= 0) {
      return
    }

    let targetPosition = this._position += delta
    this._setTransform(targetPosition, animated, duration, ease)
  }

  public moveTo(index: number, delta?: number, animated = false) {
    let newPosition = 0
    if (!delta) {
      newPosition = this._getWrapperSlidePosition(index)
    } else {
      newPosition = this._position += delta
    }

    this._index = index
    this._setTransform(newPosition, animated)
  }

  public addSlide(slide: HTMLElement, position: number) {
    if (!slide) {
      throw new Error("Cannot add an undefined slide")
    }

    if (position !== -1 && position !== 1) {
      throw new Error(`Argument out of range, 'position' must be either 1 or -1. Value ${position}`)
    }

    if (position > 0) {
      this._wrapperElement.appendChild(slide)
    } else {
      this._wrapperElement.insertBefore(slide, this._wrapperElement.children[0])
      this._index++
    }

    if (position < 0) {
      let width = slide.offsetWidth

      let style = window.getComputedStyle(slide)
      let marginLeft = style ? parseInt(style.marginLeft!, 10) : 0
      let marginRight = style ? parseInt(style.marginRight!, 10) : 0

      this.move(-(width + marginLeft + marginRight))
    }
  }

  public removeSlide(index: number) {
    const slide = this._getSlide(index)
    let width = slide.offsetWidth

    if (index <= this._index) {
      width *= -1
      this._index--
    }

    remove(slide)

    if (width < 0) {
      this.move(-width)
    }
  }

  public getSlideDelta(index: number) {
    let currentPosition = this._position
    if (this._isdragging === true) {
      currentPosition = this._dragStartPosition! - this._position
    }

    const newPosition = this._getWrapperSlidePosition(index)
    return newPosition - currentPosition
  }

  public getSlideProperties(index: number, delta = 0): SlideProperties {
    let currentOffset = this._areaOffset! + this._position + delta
    let currentLeft = currentOffset
    let currentRight = currentOffset
    let [ currentMarginLeft, currentMarginRight ] = [ 0, 0 ]

    let slide = this._getSlide(index)
    let slideIndex = parseInt(slide.getAttribute(ATTRIBUTE_INDEX)!, 10)

    for (let i = 0; i <= index; i++) {
      slide = this._getSlide(i)
      let slideStyle = window.getComputedStyle(slide)

      currentMarginLeft = parseInt(slideStyle.marginLeft!, 10)
      currentMarginRight = parseInt(slideStyle.marginRight!, 10)

      currentOffset += currentMarginLeft
      currentLeft = currentOffset
      currentRight = currentLeft + slide.offsetWidth

      if (i < index) {
        currentOffset = currentRight + currentMarginRight
      }
    }

    let visible = false
    if ((currentLeft > this._containerMin && currentLeft < this._containerMax) ||
      (currentRight > this._containerMin && currentRight < this._containerMax)) {
      visible = true
    }

    return {
      visible,
      index: slideIndex,
      left: currentLeft,
      right: currentRight,
      width: currentRight - currentLeft,
      marginLeft: currentMarginLeft,
      marginRight: currentMarginRight
    }
  }

  public getRemovableSlides(delta: number) {
    let slides = []
    let first: SlideProperties | undefined
    let last: SlideProperties | undefined

    let index = this._wrapperElement.children.length
    while (index > 0) {
      index--

      let propsNow = this.getSlideProperties(index)
      let propsNew = this.getSlideProperties(index, delta)

      if (index === this._wrapperElement.children.length - 1) {
        last = propsNew
      }

      if (index === 0) {
        first = propsNew
      }

      if (propsNow.visible === false && propsNew.visible === false &&
        index !== this._index && this._isdragging === false) {
        slides.push(true)
      } else {
        slides.push(false)
      }
    }

    slides.reverse()

    let firstToKeep = slides.indexOf(false)
    let lastToKeep = slides.lastIndexOf(false)

    for (let i = firstToKeep; i < lastToKeep; i++) {
      slides[i] = false
    }

    return {
      slides,
      first: first as SlideProperties,
      last: last as SlideProperties
    }
  }

  public getEmptySpace(left: number, right: number) {
    return {
      left: Math.max(Math.ceil(left - this._containerMin), 0),
      right: Math.max(Math.ceil(this._containerMax - right), 0)
    }
  }

  public destroy() {
    (this as any)._wrapperElement = null;
    (this as any)._slideAreaElement = null;
    (this as any)._carouselElement = null
  }

  /**
   * @deprecated use destroy() instead.
   * @todo remove in version 2.0.0
   */
  public destory() {
    this.destroy()
  }
}

export function init() {
  searchAndInitialize(".carousel", (e) => {
    new Carousel(e as HTMLElement)
  })
}

export default Carousel
