import anime, { AnimeTimelineInstance } from "animejs"
import DomElement from "../DomElement"
import * as Inputs from "../Inputs"
import { searchAndInitialize, preventDefault, internetExplorerOrEdgeVersion } from "../Utils"
import { addClass, removeClass, getAttributeReference, parentWithClass } from "../DomFunctions"

const QUERY_SEARCH_INPUT = "input.search__field"
const QUERY_BTN_CLOSE = ".search__icon-close"

const QUERY_LIVE_SUGESTIONS = ".js-suggestions"
const QUERY_LIVE_FOOTER = ".js-footer"

const CLASS_ACTIVE = "is-active"
const CLASS_OPEN = "is-open"

const CLASS_SEARCH = "search"

const ANIMATION_SUGGESTIONS_DURATION = 300
const ANIMATION_FOOTER_DURATION = 100
const ANIMATION_FOOTER_DELAY = ANIMATION_SUGGESTIONS_DURATION - ANIMATION_FOOTER_DURATION

/**
 * The search input component definition.
 */
class SearchInput extends DomElement {
  private _input: HTMLInputElement
  private _form: HTMLFormElement
  private _btnClose: HTMLElement
  private _liveSuggestions?: HTMLElement
  private _liveFooter?: HTMLElement
  private _liveContainer?: HTMLElement

  private _focusHandler: (e: Event) => void
  private _blurHandler: (e: Event) => void
  private _closeHandler: (e: Event) => void
  private _windowClickHandler: (e: Event) => void
  private _keydownHandler: (e: KeyboardEvent) => void
  private _resizeHandler: (e: Event) => void

  private _isOpen: boolean = false
  private animation!: AnimeTimelineInstance
  private easing = {
    // Equivalent to gsap's [ Power1.easeIn, Power4.easeOut ]
    inQuadOutQuint: [ 0.550, 0.085, 0.320, 1 ]
  }

  constructor(element: HTMLElement) {
    super(element)

    this._input = this.element.querySelector(QUERY_SEARCH_INPUT) as HTMLInputElement
    this._form = this.element.querySelector("form")!
    this._btnClose = this.element.querySelector(QUERY_BTN_CLOSE) as HTMLElement

    let liveSearch = getAttributeReference(this.element, "data-live")
    if (liveSearch) {
      this._liveSuggestions = liveSearch.querySelector(QUERY_LIVE_SUGESTIONS) as HTMLElement || undefined
      this._liveFooter = liveSearch.querySelector(QUERY_LIVE_FOOTER) as HTMLElement || undefined

      if (this._liveSuggestions) {
        this._liveContainer = this._liveSuggestions.parentNode as HTMLElement || undefined
      }
    }

    this._focusHandler = this._handleInputFocus.bind(this)
    this._blurHandler = this._handleInputBlur.bind(this)
    this._closeHandler = this.close.bind(this)
    this._windowClickHandler = this._handleWindowClick.bind(this)
    this._keydownHandler = this._handleKeydown.bind(this)
    this._resizeHandler = this._handleResize.bind(this)

    this._initialize()
  }

  protected _initialize() {
    this._input.addEventListener("focus", this._focusHandler)
    this._input.addEventListener("blur", this._blurHandler)

    if (internetExplorerOrEdgeVersion() > 0) {
      // This is a workaround for IE browsers where a focused
      // input's cursor bleeds trough even if hidden

      window.addEventListener("resize", this._resizeHandler)
      window.addEventListener("orientationchange", this._resizeHandler)
    }

    if (this._btnClose) {
      this._btnClose.addEventListener("click", this._closeHandler)
    }
  }

  protected _handleInputFocus() {
    this.addClass(CLASS_ACTIVE)
  }

  protected _handleInputBlur() {
    this.removeClass(CLASS_ACTIVE)
  }

  protected _handleWindowClick(event: MouseEvent) {
    let target = event.target as HTMLElement

    if (!parentWithClass(target, CLASS_SEARCH)) {
      this.close()
      return false
    }

    return true
  }

  protected _handleKeydown(event: KeyboardEvent) {
    let keycode = event.which || event.keyCode

    if (keycode === Inputs.KEY_ESCAPE) {
      this.close()
      preventDefault(event)
    }
  }

  protected _handleResize() {
    let style = window.getComputedStyle(this.element)
    if (style.display === "none") {
      this._input.blur()
    }
  }

  protected _resetMainTimeline() {
    if (this.animation) {
      this.animation.pause()
    }
    anime.remove(this._liveSuggestions!)
    anime.remove(this._liveFooter!)

    this.animation = anime.timeline()
  }

  /**
   * Gets the search input text content.
   * @returns {String} The input text.
   */
  get value() {
    return this._input.value
  }

  /**
   * Opens/activates the search input.
   */
  public open() {
    this.addClass(CLASS_OPEN)
    this._input.focus()

    setTimeout(() => {
      window.addEventListener("click", this._windowClickHandler)
      window.addEventListener("touchend", this._windowClickHandler)
      window.addEventListener("keydown", this._keydownHandler)
    }, 50)
  }

  /**
   * Closes/deactivates the search input.
   */
  public close() {
    this._form.reset()
    this.removeClass(CLASS_OPEN)

    this.closeLiveSearch()

    window.removeEventListener("click", this._windowClickHandler)
    window.removeEventListener("touchend", this._windowClickHandler)
    window.removeEventListener("keydown", this._keydownHandler)
  }

  /**
   * Opens the live search suggestions.
   */
  public openLiveSearch() {
    if (!this._liveSuggestions || this._isOpen) {
      return
    }

    this._isOpen = true
    addClass(this._liveContainer!, CLASS_OPEN)

    this._resetMainTimeline()

    this._liveSuggestions.style.display = "block"

    this.animation.add({
      targets: this._liveSuggestions,
      duration: ANIMATION_SUGGESTIONS_DURATION,
      height: this._liveSuggestions.scrollHeight + "px",
      easing: this.easing.inQuadOutQuint,
      complete: () => {
        const domEl = new DomElement(this._liveSuggestions!)
        domEl.addClass(CLASS_OPEN)
        domEl.setAttribute("style", "")
      }
    })

    if (this._liveFooter) {
      this._liveFooter.style.display = "block"

      this.animation.add({
        targets: this._liveFooter,
        duration: ANIMATION_FOOTER_DURATION,
        height: this._liveFooter.scrollHeight + "px",
        easing: this.easing.inQuadOutQuint,
        offset: ANIMATION_FOOTER_DELAY,
        complete: () => {
          const domEl = new DomElement(this._liveFooter!)
          domEl.addClass(CLASS_OPEN)
          domEl.setAttribute("style", "")
        }
      })
    }
  }

  /**
   * Closes the live search suggestions.
   */
  public closeLiveSearch() {
    if (!this._liveSuggestions || !this.isOpen) {
      return
    }

    this._isOpen = false
    this._resetMainTimeline()

    this._liveSuggestions.style.display = "block"

    this.animation.add({
      targets: this._liveSuggestions,
      duration: ANIMATION_SUGGESTIONS_DURATION,
      height: 0,
      easing: this.easing.inQuadOutQuint,
      complete: () => {
        const domEl = new DomElement(this._liveSuggestions!)
        domEl.removeClass(CLASS_OPEN)
        domEl.setAttribute("style", "")
        removeClass(this._liveContainer!, CLASS_OPEN)
      }
    })

    if (this._liveFooter) {
      this._liveFooter.style.display = "block"

      this.animation.add({
        targets: this._liveFooter,
        duration: ANIMATION_FOOTER_DURATION,
        height: 0,
        easing: this.easing.inQuadOutQuint,
        offset: 0,
        complete: () => {
          const domEl = new DomElement(this._liveFooter!)
          domEl.removeClass(CLASS_OPEN)
          domEl.setAttribute("style", "")
        }
      })
    }
  }

  /**
   * Destroys the component and clears all references.
   */
  public destroy() {
    window.removeEventListener("click", this._windowClickHandler)
    window.removeEventListener("touchend", this._windowClickHandler)
    window.removeEventListener("keydown", this._keydownHandler)

    this._input.removeEventListener("focus", this._focusHandler)
    this._input.removeEventListener("blur", this._blurHandler)

    window.removeEventListener("resize", this._resizeHandler)
    window.removeEventListener("orientationchange", this._resizeHandler)

    if (this._btnClose) {
      this._btnClose.removeEventListener("click", this._closeHandler)
    }

    (this as any)._input = null;
    (this as any)._form = null;
    (this as any)._btnClose = null;

    (this as any)._focusHandler = null;
    (this as any)._blurHandler = null;
    (this as any)._closeHandler = null;
    (this as any)._windowClickHandler = null;
    (this as any)._keydownHandler = null;

    (this as any)._liveSuggestions = null;
    (this as any)._liveFooter = null
  }

  /**
   * Determines if the SearchInput is open/visible.
   * @return {Boolean} - True if open; otherwise false.
   */
  public isOpen() {
    return this.hasClass(CLASS_OPEN)
  }
}

export function init() {
  searchAndInitialize<HTMLElement>(".search.search__input", (e) => {
    new SearchInput(e)
  })
}

export default SearchInput
