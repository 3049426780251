import { searchAndInitialize, preventDefault, find, remove, internetExplorerOrEdgeVersion, scrollIntoView } from "../Utils"
import DomElement from "../DomElement"
import * as Inputs from "../Inputs"
import * as Dom from "../DomFunctions"

const CLASS_PLACEHOLDER = "select__placeholder"
const CLASS_THUMB = "select__thumb"
const CLASS_BUTTON = "select__button"
const CLASS_DROPDOWN = "select__dropdown"

const CLASS_OPEN = "select--open"
const CLASS_CLOSED = "select--closed"
const CLASS_DISABLED = "select--disabled"
const CLASS_FILTERABLE = "select--filterable"

const CLASS_ITEM = "dropdown-item"
const CLASS_ITEM_SELECTED = "dropdown-item--selected"
const CLASS_ITEM_FOCUSED = "dropdown-item--focused"
const CLASS_ITEM_DISABLED = "dropdown-item--disabled"

const CLASS_GROUP_ITEM = "dropdown-group"
const CLASS_GROUP_HEADER = "dropdown-group__item"

const QUERY_MESSAGE = ".message"

const TIMEOUT_CLOSE = 150
const TIMEOUT_BLUR = 400

/**
 * The select component API.
 */
class Select extends DomElement<HTMLSelectElement> {
  private _openByFocus: boolean
  private _multiselection: boolean
  private _clickHandler: (e: Event) => void
  private _handleDropdownClick: (e: Event) => void
  private _keydownHandler: (e: Event) => void
  private _focusHandler: (e: Event) => void
  private _blurHandler: (e: Event) => void
  private _windowClickHandler: (e: Event) => void
  private _filterKeydownHandler: (e: Event) => void
  private _filterKeyupHandler: (e: Event) => void
  private _filterFocusHandler: (e: Event) => void

  private _wrapperElement!: DomElement
  private _dropdownElement!: DomElement<HTMLElement>

  private _selectButtonElement!: DomElement
  private _thumbElement!: DomElement

  private _placeholderOption?: HTMLOptionElement
  private _placeholderElement!: DomElement
  private _placeholderText!: string

  private _lastHandledEvent?: Event
  private _lastSelectedOption?: HTMLOptionElement

  // Minimum filter length
  private _minFilterLength = 2

  // The keyword the Select is currently filtered by
  private _activeFilter?: string

  // The options the Select was initially created upon
  // These will be used as a basis for filtering
  private _initialOptions = Array.prototype.slice.call(this.element.children)

  constructor(element: HTMLSelectElement) {
    super(element)

    this._openByFocus = false

    // Check for multi-selection
    this._multiselection = this.element.hasAttribute("multiple") === true

    // Setup event context
    this._clickHandler = this._handleClick.bind(this)
    this._handleDropdownClick = this._handleClick.bind(this)
    this._keydownHandler = this._handleKeydown.bind(this)
    this._focusHandler = this._handleFocus.bind(this)
    this._blurHandler = this._handleBlur.bind(this)
    this._windowClickHandler = this._handleWindowClick.bind(this)
    this._filterKeydownHandler = this._handleFilterKeydown.bind(this)
    this._filterKeyupHandler = this._handleFilterKeyup.bind(this)
    this._filterFocusHandler = this._handleFilterFocus.bind(this)

    this._initialize()
  }

  /**
   * Initializes the select component.
   *
   * This method inspects the select definition and its options and
   * generates new stylable DOM elements around the original select-element
   * definitions.
   * @private
   */
  protected _initialize() {
    const selectedOption = this.element.querySelector("option[selected]") as HTMLOptionElement
    const firstOption = this.element.querySelector("option") as HTMLOptionElement

    // Per default, set the last selected option to either the option with a "selected" attribute,
    // or, if not found, to the first available option
    this._lastSelectedOption = selectedOption || firstOption

    this._wrapperElement = new DomElement(this.element.parentElement!)
      .addClass(CLASS_CLOSED)

    for (let cls of this.classes) {
      this._wrapperElement.addClass(cls)
    }

    this._dropdownElement = new DomElement<HTMLElement>("div")
      .addClass(CLASS_DROPDOWN)

    if (internetExplorerOrEdgeVersion() > 0 && internetExplorerOrEdgeVersion() < 12) {
      // This is a workaround for IE browsers 11 and earlier where focusing
      // a scrollable dropdown list will close the dropdown prematurely.
      this._dropdownElement.element.addEventListener("mousedown", (event: MouseEvent) => event.preventDefault())
    }

    this._setupTarget()
    this._setupPlaceholder()

    this._wrapperElement.appendChild(this._dropdownElement)

    this._createOptions(this.element)

    this._updateSize()
    this._updateMessage()

    if (this.element.disabled) {
      this.disable()
    } else {
      this.enable()
    }
  }

  protected _setupTarget() {
    // move the id from the select element to the wrapper
    const id = this.element.getAttribute("id")
    if (id) {
      this.element.removeAttribute("id")
      this._wrapperElement.setAttribute("id", id)
    }

    // Apply the tab index
    const tabIndex = this.element.getAttribute("tabindex")
    if (tabIndex) {
      this._wrapperElement.setAttribute("tabIndex", tabIndex)
    }
  }

  protected _setupPlaceholder() {
    if (!this._selectButtonElement) {
      this._selectButtonElement = new DomElement("div")
        .addClass(CLASS_BUTTON)

      this._wrapperElement.appendChild(this._selectButtonElement)
    }

    if (!this._thumbElement) {
      this._thumbElement = new DomElement("div")
        .addClass(CLASS_THUMB)

      let thumbIcon = new DomElement("div")
        .addClass("thumb-icon")

      let loader = new DomElement("div")
        .addClass("loader-spinner")
        .addClass("loader-spinner--small")

      this._thumbElement.appendChild(loader)
      this._thumbElement.appendChild(thumbIcon)
      this._selectButtonElement.appendChild(this._thumbElement)
    }

    let placeholderText = ""

    this._placeholderOption = this.element.querySelector("option[selected][disabled]") as HTMLOptionElement || undefined

    if (this._placeholderOption) {
      placeholderText = Dom.text(this._placeholderOption)

      if (this._multiselection === true) {
        this._placeholderOption.selected = false
      }
    }

    let selectedOption = this.element.querySelector("option[selected]:not([disabled])")

    if (selectedOption) {
      placeholderText = Dom.text(selectedOption)
    }

    if (!this._placeholderElement) {
      // When the Select is filterable, create an "input" as the placeholder element, otherwise a "span"
      if (this._isFilterable()) {
        this._placeholderElement = new DomElement("input")
        this._placeholderElement.addEventListener("keyup", (e) => this._handleFilterKeyup(e))
        this._placeholderElement.addEventListener("keydown", (e) => this._handleFilterKeydown(e))
        this._placeholderElement.addEventListener("focus", (e) => this._handleFilterFocus(e))
      } else {
        this._placeholderElement = new DomElement("span")
      }

      this._placeholderElement.addClass(CLASS_PLACEHOLDER)
      this._selectButtonElement.appendChild(this._placeholderElement)
    }

    this._setPlaceholder(placeholderText)
    this._placeholderText = placeholderText

    if (selectedOption && selectedOption !== this._placeholderOption) {
      this._updatePlaceholder(true)
    }
  }

  protected _updateMessage() {
    const messageNode = this._wrapperElement.element.querySelector(QUERY_MESSAGE)
    if (messageNode !== null) {
      this._wrapperElement.appendChild(new DomElement(messageNode))
    }
  }

  private _isOptGroup(element: Element): element is HTMLOptGroupElement {
    return element.tagName.toUpperCase() === "OPTGROUP"
  }

  private _isOption(element: Element): element is HTMLOptionElement {
    return element.tagName.toUpperCase() === "OPTION"
  }

  protected _createOptions(element: HTMLSelectElement) {
    for (let i = 0; i < element.children.length; i++) {
      let child = element.children[i]

      if (this._isOptGroup(child)) {
        this._appendGroup(child as HTMLOptGroupElement)
      }

      if (this._isOption(child)) {
        let option = this._createOption(child as HTMLOptionElement)

        if (option) {
          this._dropdownElement.appendChild(option)
        }
      }
    }
  }

  protected _createOption(option: HTMLOptionElement) {
    let html = option.innerHTML

    if (this._activeFilter) {
      const sanitizedActiveFilter = this._activeFilter.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&")
      html = html.replace(new RegExp(`(${sanitizedActiveFilter})`, "gi"), "<strong>$1</strong>")
    }

    let opt = new DomElement("div")
      .addClass(CLASS_ITEM)
      .setHtml(html)

    if (option.selected) {
      opt.addClass(CLASS_ITEM_SELECTED)
    }

    if (option.disabled) {
      opt.addClass(CLASS_ITEM_DISABLED)
    }

    if (!this._isPlaceholder(option)) {
      opt.setAttribute("data-value", option.value)
      return opt
    }

    return undefined
  }

  protected _appendGroup(optgroup: HTMLOptGroupElement) {
    let label = optgroup.getAttribute("label")!

    let group = new DomElement("div")
      .addClass(CLASS_GROUP_ITEM)

    let groupHeader = new DomElement("div")
      .addClass(CLASS_GROUP_HEADER)
      .setHtml(label)

    group.appendChild(groupHeader)

    let options = optgroup.querySelectorAll("option")
    for (let entry of options) {
      let option = this._createOption(entry)
      if (option) {
        group.appendChild(option)
      }
    }

    this._dropdownElement.appendChild(group)
    return group
  }

  protected _updateSize() {
    // Note: Mirroring the DOM and measuring the items using their clientWidth was very
    // unreliable, therefore measuring was switched to the new HTML5 measureText method
    // margins and paddings arround the text are copied from the original placeholder items
    // dimension
    const placeholderStyle = window.getComputedStyle(this._placeholderElement.element)

    let paddingRight = parseFloat(placeholderStyle.paddingRight!)
    let paddingLeft = parseFloat(placeholderStyle.paddingLeft!)

    let font = this._placeholderElement.css("font")
    let textWidth = Dom.textWidth(this._placeholderText, font)
    let maxWidth = paddingLeft + paddingRight + textWidth

    let options = this._wrapperElement.element.querySelectorAll(`.${CLASS_ITEM}`)
    for (let entry of options) {
      let width = Dom.textWidth(Dom.text(entry), font) + paddingLeft + paddingRight

      if (width > maxWidth) {
        maxWidth = width
      }
    }

  }

  protected _isButtonTarget(target: EventTarget) {
    return (target === this._wrapperElement.element ||
      target === this._placeholderElement.element ||
      target === this._selectButtonElement.element ||
      target === this._thumbElement.element)
  }

  protected _isDropdownTarget(target: EventTarget) {
    let current = target as HTMLElement
    while (current !== this._dropdownElement.element && current.parentElement) {
      current = current.parentElement
    }

    return current === this._dropdownElement.element
  }

  /**
   * Updates the UI if the selection has changed and makes sure the
   * select control and the generated markup are synchronized.
   * @private
   */
  protected _selectedItemChanged(
    newItem: Element,
    autoClose = true,
    multiselect = false
  ) {
    const oldItems = this._dropdownElement.element.querySelectorAll(`.${CLASS_ITEM_SELECTED}`)

    if (!newItem) {
      setTimeout(() => this.close(), TIMEOUT_CLOSE)
      return
    }

    if (Dom.hasClass(newItem, CLASS_ITEM_DISABLED)) {
      return
    }

    if ((oldItems.length === 0) && !newItem) {
      throw new Error("Can not select undefined elements")
    }

    let oldItem = oldItems[0]

    if (multiselect === true) {
      oldItem = find(oldItems, (x) => x.getAttribute("data-value") === newItem.getAttribute("data-value"))!
    }

    let isDeselect = false

    if (newItem && oldItem && oldItem === newItem) {
      // Click on a previously selected element -> deselect
      isDeselect = true

      if (!this._placeholderOption && !multiselect) {
        // If there is no placeholder option, non multiselect options cannot be deselected
        return
      }

      delete this._lastSelectedOption
    }

    if (oldItem) {
      // Remove selection on the element
      let oldValue = oldItem.getAttribute("data-value")
      let optElement = find(this.element.options, (x) => !x.disabled && x.value === oldValue)

      if (!optElement) {
        throw new Error(`The option with value ${oldValue} does not exist`)
      }

      // Unset Select value
      optElement.selected = false
      Dom.removeClass(oldItem, CLASS_ITEM_SELECTED)
    }

    if (!isDeselect) { // Select an option
      // Select a new item
      let newValue = newItem.getAttribute("data-value")
      let optElement = find(this.element.options, (x) => !x.disabled && x.value === newValue)

      if (!optElement) {
        throw new Error(`The option with value ${newValue} does not exist`)
      }

      // Set Select value
      optElement.selected = true
      Dom.addClass(newItem, CLASS_ITEM_SELECTED)

      // Preserve selection
      this._lastSelectedOption = optElement

    } else { // Deselect an option
      // Keep track of falling back to the placeholder (if any)
      if (this._placeholderOption) {
        this._lastSelectedOption = this._placeholderOption
      }
    }

    let hasSelectedItems = true

    if (this._multiselection === false && isDeselect) {
      // Handle no selection for non-multiselect states
      this._placeholderOption!.selected = true
      hasSelectedItems = false
    }

    if (this._multiselection === true && this._getSelectedOptions().length === 0) {
      hasSelectedItems = false
    }

    // Reset the filter if filterable
    if (this._activeFilter) {
      this._clearFilter()
    }

    this._updatePlaceholder(hasSelectedItems)

    // Dispatch the changed event
    this.dispatchEvent("change")

    if (autoClose && !multiselect) {
      setTimeout(() => {
        this.close()
      }, TIMEOUT_CLOSE)
    }
  }

  protected _updatePlaceholder(hasSelectedItems: boolean) {
    let text = this._placeholderOption ? Dom.text(this._placeholderOption) : " "

    if (hasSelectedItems === true) {
      let selectedItems = this._getSelectedOptions()

      if (selectedItems.length > 0) {
        text = ""
        for (let item of selectedItems) {
          text += `${Dom.text(item)}, `
        }
        text = text.substring(0, text.length - 2)
      }
    }

    this._setPlaceholder(text)
  }

  protected _getSelectedOptions() {
    let selectedOptions: HTMLOptionElement[] = []
    if (this.element.options) {
      [].forEach.call(this.element.options, ((option: HTMLOptionElement) => {
        if (option.selected && !option.disabled) {
          selectedOptions.push(option)
        }
      }))
    }
    return selectedOptions
  }

  /**
   * Clone all of the initially set options (and optgroups) and returns them in a new array.
   * This serves as the basis for filtering. If a filter is present, it will be respected.
   */
  private getInitialOptions(): Element[] {
    const filter = this._activeFilter || ""
    const filtered: Element[] = []
    const initialOptions = this._initialOptions

    for (let i = 0; i < initialOptions.length; i++) {
      const child: Element = initialOptions[i] as Element

      if (this._isOptGroup(child)) { // handle <optgroup>
        const optGroupClone: Element = child.cloneNode(false) as Element
        let found = false

        for (let j = 0; j < child.children.length; j++) {
          const optionClone: Element = child.children[j].cloneNode(true) as Element

          // Append on match
          if (this._containsWord(optionClone.innerHTML, filter)) {
            optGroupClone.appendChild(optionClone)
            found = true
          }
        }

        // Push if any matches found
        if (found) {
          filtered.push(optGroupClone)
        }

      } else if (this._isOption(child)) { // handle <option>
        const optionClone: Element = child.cloneNode(true) as Element

        // Push on match
        if (this._containsWord(optionClone.innerHTML, filter)) {
          filtered.push(optionClone)
        }
      }
    }

    return filtered
  }

  /**
   * Returns true if a text contains a given keyword, e.g. in "ca" in "Car"
   */
  private _containsWord(text: string, keyword: string): boolean {
    return text.toLowerCase().indexOf(keyword.toLowerCase()) > -1
  }

  protected _handleFocus() {
    this.open()
    this._openByFocus = true

    setTimeout(() => {
      this._openByFocus = false
    }, TIMEOUT_BLUR)
  }

  protected _handleBlur() {
    this.close()
  }

  protected _handleClick(event: MouseEvent) {
    let handled = false

    if (this._lastHandledEvent === event) {
      this._lastHandledEvent = undefined
      return
    }

    if (this._isButtonTarget(event.target!) && this._openByFocus === false) {
      // handle header item clicks and toggle dropdown
      this.toggle()
      handled = true
    }

    let newItem = event.target as Element

    if (!handled && Dom.hasClass(newItem, CLASS_ITEM)) {
      // handle clicks on dropdown items
      this._selectedItemChanged(newItem, true, this._multiselection)
      handled = true
    }

    if (handled) {
      this._lastHandledEvent = event
      preventDefault(event)
    }
  }

  protected _handleWindowClick(event: MouseEvent) {
    if (this._isDropdownTarget(event.target!) || this._isButtonTarget(event.target!)) {
      return
    }

    this.close()
  }

  protected _focusOptionStartingWith(keycode: number, startIndex: number, options: NodeListOf<HTMLElement>) {
    for (let index = startIndex; index < options.length; index++) {
      let item = new DomElement(options[index])
      let value = item.innerText.toLowerCase()

      if (index > options.length) {
        index = 0
      }

      if (value.startsWith(Inputs.getKeyValue(keycode))) {
        let newOption = new DomElement(options[index])

        if (!newOption.hasClass(CLASS_ITEM_DISABLED)) {
          scrollIntoView(options[index])
          newOption.addClass(CLASS_ITEM_FOCUSED)
          return newOption
        }
      }
    }
    return undefined
  }

  protected _handleKeydown(event: KeyboardEvent) {
    let evt = event || window.event
    let keycode = event.which || event.keyCode

    if (keycode === Inputs.KEY_ESCAPE) {
      // handle Escape key (ESC)
      if (this.isOpen()) {
        this.close()
      }
      evt.preventDefault()
      return
    }

    if (keycode === Inputs.KEY_ARROW_UP || keycode === Inputs.KEY_ARROW_DOWN) {
      // Up and down arrows

      let options = this._wrapperElement.element.querySelectorAll(`.${CLASS_ITEM}`) as NodeListOf<HTMLElement>
      if (options.length > 0) {

        let newIndex = 0
        let oldOption

        let focusedElement = this._wrapperElement.find(`.${CLASS_ITEM_FOCUSED}`)
        let searchFor = focusedElement ? CLASS_ITEM_FOCUSED : CLASS_ITEM_SELECTED

        let newElement

        for (let index = 0; index < options.length; index++) {
          let direction = keycode === Inputs.KEY_ARROW_DOWN ? 1 : -1

          let item = new DomElement(options[index])

          // search for selected or focusedElement elements
          if (item.hasClass(searchFor)) {
            oldOption = item
            newIndex = index

            // get the next not disabled element in the appropriate direction
            for (let count = 0; count < options.length; count++) {
              newIndex += direction
              newIndex %= options.length

              if (newIndex < 0) {
                newIndex = options.length - 1
              }

              newElement = new DomElement(options[newIndex])
              if (!newElement.hasClass(CLASS_ITEM_DISABLED)) {
                break
              }
            }
          }
        }

        // set the new element focused
        scrollIntoView(options[newIndex])
        let newOption = new DomElement(options[newIndex])
        newOption.addClass(CLASS_ITEM_FOCUSED)

        if (oldOption) {
          oldOption.removeClass(CLASS_ITEM_FOCUSED)
        }
      }

      evt.preventDefault()
      return
    }

    if (Inputs.getKeyValue(keycode) && !this._isFilterable()) {
      // Keyboard keys

      let options = this._wrapperElement.element.querySelectorAll(`.${CLASS_ITEM}`) as NodeListOf<HTMLElement>
      if (options.length > 0) {

        let oldFocusIndex = 0
        let hasFocusedOption = false

        for (let index = 0; index < options.length; index++) {
          let item = new DomElement(options[index])

          if (item.hasClass(CLASS_ITEM_FOCUSED)) {
            item.removeClass(CLASS_ITEM_FOCUSED)

            let value = item.innerText.toLowerCase()
            if (value.startsWith(Inputs.getKeyValue(keycode))) {
              hasFocusedOption = true
              oldFocusIndex = index
            }
          }
        }

        let newOption = this._focusOptionStartingWith(keycode, hasFocusedOption ? oldFocusIndex + 1 : 0, options)
        if (newOption === undefined){
          this._focusOptionStartingWith(keycode, 0, options)
        }
      }

      evt.preventDefault()
      return
    }

    if (keycode === Inputs.KEY_ENTER || keycode === Inputs.KEY_TAB) {
      // Handle enter and tab key by selecting the currently focused element
      let newItem = this._dropdownElement.element.querySelector(`.${CLASS_ITEM_FOCUSED}`)!
      this._selectedItemChanged(newItem, true, this._multiselection)
    }
  }

  /**
   * Fired when the user presses a key in the filter field
   */
  private _handleFilterKeydown(e: KeyboardEvent): void {
    const keycode = e.which || e.keyCode

    // If the user hits the enter key while filtering and there's a single match, select it
    if (keycode === Inputs.KEY_ENTER) {
      const dropdownElements = this._dropdownElement.element.querySelectorAll(`.${CLASS_ITEM}`)

      if (dropdownElements.length === 1) {
        this._selectedItemChanged(dropdownElements[0], true, this._multiselection)
        e.stopPropagation()
      }
    }
  }

  /**
   * Fired when the user releases a key in the filter field
   */
  private _handleFilterKeyup(e: KeyboardEvent): void {
    const target = e.target as HTMLInputElement

    // Filter has changed
    if (target.value !== this._activeFilter && target.value !== this._placeholderText && target.value !== this._lastSelectedOption!.innerHTML) {
      this._setFilter(target.value)
    }
  }

  /**
   * Fired when the user focusses the filter input field
   */
  private _handleFilterFocus(e: FocusEvent): void {
    const target = e.target as HTMLInputElement

    setTimeout(() => {
      target.select()
    })
  }

  /**
   * Filters the Select by a given filter keyword
   * @param filter Keyword to filter by
   */
  private _setFilter(filter: string = ""): void {
    this._activeFilter = (filter.length >= this._minFilterLength) ? filter : ""
    this.setOptions(this.getInitialOptions())
  }

  /**
   * Resets the filter
   */
  private _clearFilter(): void {
    delete this._activeFilter
    this.setOptions(this.getInitialOptions())
  }

  /**
   * Set new content and reload the Select
   * @param elements Array of new option (or optgroup) elements to display
   */
  private setOptions(options: Element[]): void {
    this._emptyNode(this.element)

    options.forEach((option) => {
      this.element.appendChild(option)
    })

    // Preserve selected value if the selected
    this.element.value = this._lastSelectedOption!.value

    this.reload()
  }

  /**
   * Clear all children of a given node
   * @param node Node
   */
  private _emptyNode(node: Node): void {
    while (node.firstChild) {
      node.removeChild(node.firstChild)
    }
  }

  /**
   * Returns whether an option is a placeholder option
   */
  private _isPlaceholder(option: HTMLOptionElement): boolean {
    return option.hasAttribute("disabled") && option.hasAttribute("selected")
  }

  /**
   * Update placeholder value
   * @param text Content of the placeholder
   */
  protected _setPlaceholder(text: string): void {
    if (this._placeholderElement && text) {
      if (this._isFilterable()) {
        (this._placeholderElement as DomElement<HTMLInputElement>).element.value = text
      } else {
        this._placeholderElement.setHtml(text)
      }
    }
  }

  /**
   * Gets the value of the currently selected option.
   * If multiple selection is enabled this property returns an array of values.
   */
  get value() {
    if (this._multiselection) {
      return this._getSelectedOptions().map((x) => x.value)
    }

    if (this.element.value === "") {
      return null
    }

    return this.element.value
  }

  /**
   * Enables or disables the select component depending on the
   * 'value' parameter.
   * @param {value} If true disables the control; false enables it.
   */
  set disabled(value: boolean) {
    if (value) {
      this.disable()
    } else {
      this.enable()
    }
  }

  /**
   * Reloads the dropdown's option data definitions from the DOM and updates
   * the generated dropdown display items.
   */
  public reload() {
    // Remove all existing child elements
    this._emptyNode(this._dropdownElement.element)

    if (this._activeFilter === undefined) { // If the user is filtering, let the placeholder "input" alive
      this._setupPlaceholder()
    }

    this._createOptions(this.element)

    this._updateSize()
    this._updateMessage()

    if (!this._isFilterable()) {
      this._updatePlaceholder(!!this.value)
    }
  }

  /**
   * Sets the select control to the enabled state.
   */
  public enable() {
    this.element.removeAttribute("disabled")
    this._wrapperElement.removeClass(CLASS_DISABLED)

    window.addEventListener("click", this._windowClickHandler)

    this._wrapperElement.element.addEventListener("click", this._clickHandler)
    this._wrapperElement.element.addEventListener("keydown", this._keydownHandler)
    this._wrapperElement.element.addEventListener("focus", this._focusHandler)
    this._wrapperElement.element.addEventListener("blur", this._blurHandler)
  }

  /**
   * Sets the select control to the disabled state.
   */
  public disable() {
    this.element.setAttribute("disabled", "")
    this._wrapperElement.addClass(CLASS_DISABLED)

    window.removeEventListener("click", this._windowClickHandler)

    this._wrapperElement.element.removeEventListener("click", this._clickHandler)
    this._wrapperElement.element.removeEventListener("keydown", this._keydownHandler)
    this._wrapperElement.element.removeEventListener("focus", this._focusHandler)
    this._wrapperElement.element.removeEventListener("blur", this._blurHandler)

    this.close()
  }

  /**
   * Toggles the open/closed state of the select dropdown.
   */
  public toggle() {
    if (this.isOpen()) {
      this.close()
    } else {
      this.open()
    }
  }

  /**
   * Gets if the select dropdown is open or closed.
   * @return {boolean} True if open; otherwise false.
   */
  public isOpen() {
    return this._wrapperElement.hasClass(CLASS_OPEN)
  }

  /**
   * Opens the select dropdown.
   */
  public open() {
    if (!this.isOpen()) {
      this._openByFocus = false

      this._wrapperElement.removeClass(CLASS_CLOSED)
      this._wrapperElement.addClass(CLASS_OPEN)

      this._dropdownElement.element.addEventListener("click", this._handleDropdownClick)
      this._dropdownElement.element.addEventListener("tap", this._handleDropdownClick)
    }
  }

  /**
   * Closes the select dropdown.
   */
  public close() {
    if (this.isOpen()) {
      this._openByFocus = false

      this._wrapperElement.removeClass(CLASS_OPEN)
      this._wrapperElement.addClass(CLASS_CLOSED)

      // If the Select is filterable and therefore has an input field,
      // reset the value of it to the chosen option
      if (this._isFilterable()) {
        // Unfocus input field
        (this._placeholderElement.element as HTMLInputElement).blur()

        if (!this._activeFilter || this._activeFilter === this._lastSelectedOption!.innerHTML) {
          this._setPlaceholder(this._lastSelectedOption!.innerHTML)
        }
      }

      this._dropdownElement.element.removeEventListener("click", this._handleDropdownClick)
      this._dropdownElement.element.removeEventListener("tap", this._handleDropdownClick)

      let focusedItem = this._wrapperElement.find(`.${CLASS_ITEM_FOCUSED}`)

      if (focusedItem) {
        focusedItem.removeClass(CLASS_ITEM_FOCUSED)
      }
    }
  }

  /**
   * Returns true when the element has the filter modifier class
   */
  private _isFilterable(): boolean {
    return this._wrapperElement.hasClass(CLASS_FILTERABLE)
  }

  /**
   * Destroys the component and clears all references.
   */
  public destroy() {
    window.removeEventListener("click", this._windowClickHandler)

    if (this._dropdownElement) {
      this._dropdownElement.element.removeEventListener("click", this._handleDropdownClick)
      this._dropdownElement.element.removeEventListener("tap", this._handleDropdownClick)

      remove(this._dropdownElement.element);
      (this as any)._dropdownElement = undefined
    }

    if (this._placeholderElement) {
      this._placeholderElement.removeEventListener("keydown", this._filterKeydownHandler)
      this._placeholderElement.removeEventListener("keyup", this._filterKeyupHandler)
      this._placeholderElement.removeEventListener("focus", this._filterFocusHandler)
    }

    if (this._wrapperElement) {
      this._wrapperElement.element.removeEventListener("click", this._clickHandler)
      this._wrapperElement.element.removeEventListener("keydown", this._keydownHandler)
      this._wrapperElement.element.removeEventListener("focus", this._focusHandler)
      this._wrapperElement.element.removeEventListener("blur", this._blurHandler);

      (this as any)._wrapperElement = undefined
    }

    if (this._selectButtonElement) {
      remove(this._selectButtonElement.element);
      (this as any)._selectButtonElement = undefined
    }

    this.removeClass(CLASS_CLOSED)
  }
}

export function init() {
  searchAndInitialize<HTMLSelectElement>("select", (e) => {
    new Select(e)
  })
}

export default Select
