import { preventDefault } from "../Utils"
import DomElement from "../DomElement"

const CLASS_NOTIFICATION = "notification-header"

const CLASS_OPEN = "notification--open"
const CLASS_BUTTON_CLOSE = "notification__close"

/**
 * Notification component.
 * @namespace Notification
 */

/**
 * The message click callback function.
 * @memberof Notification
 * @callback Notification~Click
 * @property {NotificationHeader} item - The current notification header instance.
 * @returns {boolean} Return true if the notification should be closed; return false if the
 *                    notification should remain open.
 */
export interface MessageClickCallback {
  (header: NotificationHeader): boolean | undefined
}

/**
 * The cancel callback function.
 * @memberof Notification
 * @callback Notification~Cancel
 * @property {NotificationHeader} item - The current notification header instance.
 */
export interface CancelCallback {
  (header: NotificationHeader): void
}

/**
 * Creates and shows a notification with the specified message.
 * @memberof Notification
 * @param {String} containerId - The id of the container on where to show the notification.
 * @param {String} message - The message to show.
 * @param {Notification~Click} messageClickCallback - The callback that gets called when the user clicks on the notification message text.
 * @param {Notification~Cancel} cancelCallback - The callback that gets called when the user cancels the notification by closing it.
 * @param {String} modifierClass - The css modifier class for the notification; this is an optional parameter
 * @returns {NotificationHeader} The notification header item instance.
 */
export function showOnHeader(
  containerId: string,
  message: string,
  messageClickCallback?: MessageClickCallback,
  cancelCallback?: CancelCallback,
  modifierClass?: string
) {

  const containerE = document.querySelector(`#${containerId}`)
  if (!containerE) {
    throw new Error(`Could not find the container with id ${containerId}`)
  }

  const containerElement = new DomElement(containerE)
  const notificationElement = new NotificationHeader()

  if (modifierClass) {
    notificationElement.addClass(modifierClass)
  }

  notificationElement.message = message
  notificationElement.messageClickCallback = messageClickCallback
  notificationElement.cancelCallback = cancelCallback

  containerElement.appendChild(notificationElement)
  notificationElement._open()

  return notificationElement
}

/**
 * A component for displaying notifications on the page-header.
 * @inner
 * @memberof Notification
 */
export class NotificationHeader extends DomElement {
  private _closeHandler: (event: Event) => void
  private _clickHandler: (event: Event) => void

  private _callback?: MessageClickCallback
  private _cancelCallback?: CancelCallback

  private _closeButton!: DomElement<Element>
  private _notificationBody!: DomElement<Element>

  constructor() {
    super("div")

    this._closeHandler = this._handleClose.bind(this)
    this._clickHandler = this._handleClick.bind(this)

    this._initialize()
  }

  /**
   * Initializes the range modal component.
   * @private
   */
  protected _initialize() {
    this.addClass(CLASS_NOTIFICATION)
    this.addClass(CLASS_OPEN)

    const notificationContent = new DomElement("div")
      .addClass("notification__content")

    this.appendChild(notificationContent)

    this._notificationBody = new DomElement("div")
      .addClass("notification__body")

    notificationContent.appendChild(this._notificationBody)

    this._closeButton = new DomElement("button")
      .addClass(CLASS_BUTTON_CLOSE)
      .addClass("notification-cancel")
      .setAttribute("aria-label", "Close")

    const closeIcon = new DomElement("i")
      .addClass("icon")
      .addClass("icon-022-close")
      .setAttribute("aria-hidden", "true")

    this._closeButton.appendChild(closeIcon)
    notificationContent.appendChild(this._closeButton)

    this.element.addEventListener("click", this._clickHandler)
  }

  protected _handleClick(event: MouseEvent) {
    preventDefault(event)

    let closeNotification = true
    if (this._callback) {
      if (this._callback(this) === false) {
        closeNotification = false
      }
    }

    if (closeNotification === true) {
      this.close()
    }
  }

  protected _handleClose(event: Event) {
    preventDefault(event)
    event.stopPropagation()

    if (this._cancelCallback) {
      this._cancelCallback(this)
    }

    this.close()
  }

  protected _close() {
    this.removeClass(CLASS_OPEN)
    this._closeButton.element.removeEventListener("click", this._closeHandler)

    const el = this.element
    setTimeout(() => {
      // remove the element from the dom
      if (el && el.parentElement) {
        el.parentElement.removeChild(el)
      }
    }, 300)
  }

  // called by showOnHeader
  public _open() {
    this.addClass(CLASS_OPEN)

    this._closeButton.element.addEventListener("click", this._closeHandler)
    this.dispatchEvent("opened")
  }

  set messageClickCallback(callback: MessageClickCallback | undefined) {
    this._callback = callback
  }

  /**
   * Sets the cancel callback function.
   * @param {function} - The callback function to call.
   */
  set cancelCallback(callback: CancelCallback | undefined) {
    this._cancelCallback = callback
  }

  /**
   * Sets the notification message.
   * @param {String} - The message to set.
   */
  set message(value: string) {
    this._notificationBody.setHtml(value)
  }

  /**
   * Closes the notification.
   */
  public close() {
    this._close()
    this.dispatchEvent("closed")
  }
}
