import anime, { AnimeTimelineInstance } from "animejs"
import { searchAndInitialize } from "../Utils"
import DomElement from "../DomElement"
import * as Dom from "../DomFunctions"
import SearchInput from "../search/SearchInput"

const CLASS_OPEN = "is-open"
const CLASS_ACTIVE = "is-active"

const QUERY_NAV_HAMBURGER = ".nav-hamburger"
const QUERY_NAV_HB_BODY = ".nav__primary"

const CLASS_NAV_LINK = "nav-link--header"
const QUERY_NAV_LINK_ACTIVE = ".nav-link--header.is-active"

const QUERY_NAV_MOBILE = ".nav__level1 .nav__mainnav .nav__primary"
const QUERY_NAV_LEVEL0 = ".nav__level0"
const QUERY_NAV_LEVEL0_CONTAINER = ".nav__level0 .nav__subnav"
const QUERY_SECTION_OPEN = ".nav-section.is-open"

const QUERY_NAV_LEVEL1 = ".nav__level1 .nav__mainnav"

const QUERY_NAV_LEVEL0_LINK = ".nav-link.nav-link--header"
const QUERY_NAV_LEVEL1_LINK = ".nav-link--header"

const QUERY_NAV_COLUMN = ".nav-col"
const QUERY_NAV_COLUMN_ACTIVE = ".nav-col.is-active"

const QUERY_NAV_BODY = ".nav-body"
const QUERY_NAV_FOOTER = ".nav-footer"

const QUERY_SEARCH_ICON = ".nav-search"
const QUERY_SEARCH_FIELD = ".search__input"
const CLASS_SEARCH_DESKTOP = "search--desktop"

const ANIMATION_START_DELAY = 200
const ANIMATION_OFFSET = 50

const ANIMATION_BODY_DURATION = 300
const ANIMATION_FOOTER_DURATION = 100

/**
 * The navigation component definition.
 */
class Navigation extends DomElement {
  public _navLevel0: HTMLElement
  public _navLevel0Body: HTMLElement
  public _navLevel1: HTMLElement

  public _hamburgerElement: HTMLElement

  private _navMobile: HTMLElement

  private _searchComponents: SearchInput[]

  private _level0ClickHandler: (e: Event) => void
  private _level1ClickHandler: (e: Event) => void
  private _windowClickHandler: (e: Event) => void
  private _searchClickHandler: (e: Event) => void

  private animation: AnimeTimelineInstance = anime.timeline()
  private easing = {
    // Equivalent to gsap's [ Power1.easeIn, Power4.easeOut ]
    inQuadOutQuint: [ 0.550, 0.085, 0.320, 1 ]
  }

  private _searchDesktop?: SearchInput

  constructor(element: Element) {
    super(element)

    this._navLevel0 = this.element.querySelector(QUERY_NAV_LEVEL0) || document.createElement("div")
    this._navLevel0Body = this.element.querySelector(QUERY_NAV_LEVEL0_CONTAINER) || document.createElement("div")
    this._navLevel1 = this.element.querySelector(QUERY_NAV_LEVEL1) || document.createElement("div")

    this._navMobile = this.element.querySelector(QUERY_NAV_MOBILE) || document.createElement("div")
    if (!this._navMobile.parentElement) {
      let dummyParent = document.createElement("div")
      dummyParent.appendChild(this._navMobile)
    }

    this._hamburgerElement = this.element.querySelector(QUERY_NAV_HAMBURGER) || document.createElement("div")
    this._searchComponents = []

    this._level0ClickHandler = this._handleLevel0Click.bind(this)
    this._level1ClickHandler = this._handleLevel1Click.bind(this)
    this._windowClickHandler = this._handleWindowClick.bind(this)
    this._searchClickHandler = this._handleSearchClick.bind(this)

    this._initialize()
  }

  protected _resetMainTimeline(...elements: HTMLElement[]) {
    this.animation.pause()
    for (let el of elements) {
      anime.remove(el!)
    }
    this.animation = anime.timeline()
  }

  protected _isMobile() {
    return Dom.isHidden(this._hamburgerElement, true) === false
  }

  protected _handleLevel0Click(event: MouseEvent) {
    const isDesktop = !this._isMobile()

    if (isDesktop) {
      let navItems = new NavigationItems(this)
        .fromLevel0(event.target as HTMLElement)

      if (!navItems.section) {
        return
      }

      let previousNavLink = this._navLevel0.querySelector(QUERY_NAV_LINK_ACTIVE)! as HTMLElement
      let previousNavSection = this._navLevel0.querySelector(QUERY_SECTION_OPEN)! as HTMLElement

      this._toggleContainer(
        navItems.link,
        this._navLevel0Body,
        navItems.section,
        undefined,
        previousNavLink,
        this._navLevel0Body,
        previousNavSection,
        undefined,
        true
      )
    }
  }

  protected _handleLevel1Click(event: MouseEvent) {
    let navItems = new NavigationItems(this)
      .fromLevel1(event.target as HTMLElement)

    let prevItems = navItems.previousLevel1()

    this._toggleContainer(
      navItems.link,
      navItems.container,
      navItems.section,
      navItems.footer,
      prevItems.link,
      prevItems.container,
      prevItems.section,
      prevItems.footer,
      false
    )

    return false
  }

  protected _toggleContainer(
    navLink: HTMLElement,
    navContainer?: HTMLElement,
    navSection?: HTMLElement,
    navFooter?: HTMLElement,
    previousNavLink?: HTMLElement,
    previousNavContainer?: HTMLElement,
    previousNavSection?: HTMLElement,
    previousNavFooter?: HTMLElement,
    animateContainer = false
  ) {
    const isDesktop = !this._isMobile()

    if (previousNavLink && previousNavLink !== navLink && navLink !== this._hamburgerElement) {
      Dom.removeClass(previousNavLink, CLASS_ACTIVE)
    }

    this._resetMainTimeline(navContainer!, navSection!, navFooter!, previousNavContainer!, previousNavSection!, previousNavFooter!)

    if (Dom.hasClass(navLink, CLASS_ACTIVE)) {
      Dom.removeClass(navLink, CLASS_ACTIVE)

      if (isDesktop) {
        this._onNavigationClosed()

        this._closeSection(navContainer, navSection, navFooter, true, animateContainer)
      } else if (navLink === this._hamburgerElement) {
        // Close mobile navigation
        this._onNavigationClosed()

        this._closeSection(navContainer, navSection, undefined, false, false)
      } else if (!isDesktop) {
        // Close the section
        this._closeSection(navContainer, navSection, navFooter, true, animateContainer)
      }
    } else {
      Dom.addClass(navLink, CLASS_ACTIVE)

      if (isDesktop) {
        Dom.addClass(this._navMobile, CLASS_OPEN)
        this._onNavigationOpened()

        if (previousNavContainer && previousNavSection) {
          this._closeSection(previousNavContainer, previousNavSection, previousNavFooter, true, animateContainer)
        }
        this._openSection(navContainer, navSection, navFooter, true, animateContainer)
      } else if (navLink === this._hamburgerElement) {
        // Open mobile navigation
        this._onNavigationOpened()

        this._openSection(navContainer, navSection, undefined, false, false)
      } else if (!isDesktop) {
        // Open section
        if (previousNavContainer && previousNavSection) {
          this._closeSection(previousNavContainer, previousNavSection, previousNavFooter, true, animateContainer)
          this.animation = anime.timeline()
        }
        this._openSection(navContainer, navSection, navFooter, true, animateContainer)
      }
    }
  }

  protected _onNavigationOpened() {
    Dom.addClass(this._navMobile, CLASS_OPEN)
    Dom.addClass(this._navMobile.parentElement!, CLASS_OPEN)
    Dom.addClass(this._hamburgerElement, CLASS_ACTIVE)

    window.addEventListener("click", this._windowClickHandler)
    window.addEventListener("touchend", this._windowClickHandler)
  }

  protected _onNavigationClosed() {
    Dom.removeClass(this._navMobile, CLASS_OPEN)
    Dom.removeClass(this._navMobile.parentElement!, CLASS_OPEN)
    Dom.removeClass(this._hamburgerElement, CLASS_ACTIVE)

    window.removeEventListener("click", this._windowClickHandler)
    window.removeEventListener("touchend", this._windowClickHandler)
  }

  protected _handleWindowClick(event: MouseEvent) {
    let target = event.target as HTMLElement

    while (target !== this.element && target.parentElement) {
      target = target.parentElement
    }

    if (target !== this.element) {
      this.close()
      return false
    }

    return true
  }

  protected _openSection(
    navContainer?: HTMLElement,
    navSection?: HTMLElement,
    navFooter?: HTMLElement,
    animateColumns = true,
    animateContainer = false
  ) {
    if (!navSection || !navContainer) {
      return
    }

    let activeItems = navSection.querySelectorAll(QUERY_NAV_COLUMN)

    if (animateContainer === true) {
      let container = navContainer
      navContainer = navSection
      navSection = container
    }

    Dom.addClass(navContainer!, CLASS_OPEN)

    navSection.style.display = "block"

    this.animation.add({
      targets: navSection,
      duration: ANIMATION_BODY_DURATION,
      easing: this.easing.inQuadOutQuint,
      height: animateContainer ? navContainer.scrollHeight : navSection.scrollHeight,
      complete: () => {
        Dom.addClass(navSection!, CLASS_OPEN)
        new DomElement(navSection!).setAttribute("style", "")
      }
    })

    if (navFooter) {
      const navItems = navFooter.querySelectorAll(QUERY_NAV_COLUMN)
      for (let item of navItems) {
        Dom.addClass(item, CLASS_ACTIVE)
      }

      navFooter.style.display = "block"

      this.animation.add({
        targets: navFooter,
        duration: ANIMATION_FOOTER_DURATION,
        easing: this.easing.inQuadOutQuint,
        height: navFooter.scrollHeight,
        offset: `-=${ANIMATION_FOOTER_DURATION}`,
        complete: () => {
          Dom.addClass(navFooter!, CLASS_OPEN)
          new DomElement(navFooter!).setAttribute("style", "")
        }
      })
    }

    if (animateColumns === true) {
      let delay = ANIMATION_START_DELAY

      for (let item of activeItems) {
        this.animation.add({
          targets: item,
          duration: 0,
          offset: delay,
          complete: () => {
            Dom.addClass(item, CLASS_ACTIVE)
          }
        })
        delay += ANIMATION_OFFSET
      }
    }
  }

  protected _closeSection(
    navContainer?: HTMLElement,
    navSection?: HTMLElement,
    navFooter?: HTMLElement,
    animateColumns = true,
    animateContainer = false
  ) {
    if (!navSection || !navContainer) {
      return
    }

    let activeItems = navSection.querySelectorAll(QUERY_NAV_COLUMN_ACTIVE)

    if (animateContainer === true) {
      let container = navContainer
      navContainer = navSection
      navSection = container
    }

    if (animateColumns === true) {
      for (let active of activeItems) {
        Dom.removeClass(active, CLASS_ACTIVE)
      }
    }

    this.animation.add({
      targets: navSection,
      duration: ANIMATION_BODY_DURATION,
      easing: this.easing.inQuadOutQuint,
      height: 0,
      offset: 0,
      complete: () => {
        Dom.removeClass(navContainer!, CLASS_OPEN)
        Dom.removeClass(navSection!, CLASS_OPEN)
        navSection!.style.height = null
      }
    })

    if (navFooter) {
      for (let active of navFooter.querySelectorAll(QUERY_NAV_COLUMN_ACTIVE)) {
        Dom.removeClass(active, CLASS_ACTIVE)
      }

      this.animation.add({
        targets: navFooter,
        duration: ANIMATION_FOOTER_DURATION,
        easing: this.easing.inQuadOutQuint,
        height: 0,
        offset: 0,
        complete: () => {
          Dom.removeClass(navFooter!, CLASS_OPEN)
          navFooter!.style.height = null
        }
      })
    }
  }

  protected _handleSearchClick() {
    if (this._searchDesktop) {
      this._searchDesktop.open()
    }
  }

  /**
   * Initializes the navigation component.
   * @private
   */
  protected _initialize() {
    for (let navLink of this._navLevel0.querySelectorAll(QUERY_NAV_LEVEL0_LINK)) {
      navLink.addEventListener("click", this._level0ClickHandler)
    }

    for (let navLink of this._navLevel1.querySelectorAll(QUERY_NAV_LEVEL1_LINK)) {
      navLink.addEventListener("click", this._level1ClickHandler)
    }

    this._hamburgerElement.addEventListener("click", this._level1ClickHandler)

    // Desktop search icon
    let searchIcon = this.element.querySelector(QUERY_SEARCH_ICON)
    if (searchIcon) {
      searchIcon.addEventListener("click", this._searchClickHandler)
    }

    for (let search of this.element.querySelectorAll(QUERY_SEARCH_FIELD)) {
      let searchComponent = new SearchInput(search as HTMLElement)

      if (Dom.hasClass(search, CLASS_SEARCH_DESKTOP) || Dom.hasClass(search.parentElement!, CLASS_SEARCH_DESKTOP)) {
        this._searchDesktop = searchComponent
      }

      this._searchComponents.push(searchComponent)
    }
  }

  /**
   * Closes the navigation.
   */
  public close() {
    let isMoble = this._isMobile()

    let level1 = this._navLevel1.querySelector(QUERY_NAV_LINK_ACTIVE) as HTMLElement
    let level0 = this._navLevel0.querySelector(QUERY_NAV_LINK_ACTIVE) as HTMLElement

    if (!level1 && isMoble && Dom.hasClass(this._hamburgerElement, CLASS_ACTIVE)) {
      level1 = this._hamburgerElement
    }

    let navItems

    if (level1) {
      navItems = new NavigationItems(this).fromLevel1(level1)
    } else if (level0) {
      navItems = new NavigationItems(this).fromLevel0(level0)
    }

    if (navItems) {
      this._resetMainTimeline(navItems.container!, navItems.section!, navItems.footer!)
      Dom.removeClass(navItems.link, CLASS_ACTIVE)
      this._onNavigationClosed()
      this._closeSection(navItems.container!, navItems.section!, navItems.footer, !isMoble, false)
    }

  }
}

class NavigationItems {
  private _navigation: Navigation
  private _link!: HTMLElement
  private _container?: HTMLElement
  private _section?: HTMLElement
  private _footer?: HTMLElement
  constructor(nav: Navigation) {
    this._navigation = nav
  }

  get link() {
    return this._link
  }

  get container() {
    return this._container
  }

  get section() {
    return this._section
  }

  get footer() {
    return this._footer
  }

  public fromLevel0(navLink: HTMLElement) {
    while (!Dom.hasClass(navLink, CLASS_NAV_LINK) && navLink.parentElement) {
      navLink = navLink.parentElement
    }

    this._link = navLink

    let toggleId = navLink.getAttribute("data-toggle")
    this._container = this._navigation._navLevel0Body
    this._section = this._navigation._navLevel0.querySelector(`#${toggleId}`)! as HTMLElement

    return this
  }

  public fromLevel1(navLink: HTMLElement) {
    while (navLink.parentElement) {
      if ((navLink === this._navigation._hamburgerElement) || Dom.hasClass(navLink, CLASS_NAV_LINK)) {
        break
      }

      navLink = navLink.parentElement
    }

    this._link = navLink
    this._container = navLink.parentElement! as HTMLElement
    this._section = this._container!.querySelector(QUERY_NAV_BODY)! as HTMLElement
    this._footer = this._container!.querySelector(QUERY_NAV_FOOTER)! as HTMLElement

    if (navLink === this._navigation._hamburgerElement) {
      this._container = this._navigation._navLevel1
      this._section = this._container.querySelector(QUERY_NAV_HB_BODY)! as HTMLElement
    }

    return this
  }

  public previousLevel1() {
    let prev = new NavigationItems(this._navigation)

    prev._link = this._navigation._navLevel1.querySelector(QUERY_NAV_LINK_ACTIVE)! as HTMLElement
    prev._container = prev._link ? prev._link.parentElement! : undefined
    prev._section = prev._container ? prev._container.querySelector(QUERY_NAV_BODY)! as HTMLElement : undefined
    prev._footer = prev._container ? prev._container.querySelector(QUERY_NAV_FOOTER)! as HTMLElement : undefined

    return prev
  }

  public isHamburger() {
    return this._link === this._navigation._hamburgerElement
  }
}

export function init() {
  searchAndInitialize(".nav", (e) => {
    new Navigation(e)
  })
}

export default Navigation
